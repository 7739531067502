<template>
    <div class="container">
        <div>
            <div class="search_big_box">
                <div class="search_box">
                    <div>
                        <el-input
                                @input="usernameInput()"
                                placeholder="请输入公司名称进行在线测试"
                                v-model="input4"
                                @keyup.enter.native="fns"

                        >
                            <i slot="prefix"   class="el-input__icon el-icon-search"></i>
                        </el-input>
                    </div>
                    <div  class="search" @click="search_inputs"><span>在线测试</span></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "qiyecx",
        data(){
            return{
                input4:"",
                arr:["烟","酒店","餐饮","旅行","零售","批发","房地产开发",
                    "物流","运输","娱乐","劳务",
                    "学校","幼儿园","俱乐部","分公司","商贸","商务",
                    "建筑","合伙","保险","销售","住宿"],
                flag:true,

            }
        },
        methods:{
            fns(){
                this.search_inputs()
            },
            /*监听事件*/
            usernameInput(){
                for(var i=0;i<this.arr.length;i++){
                    if(this.input4.includes(this.arr[i])){
                        this.$message("输入不合格")
                        return
                    }
                }
            },
            search_inputs(){
                for(var i=0;i<this.arr.length;i++){
                    if(this.input4.includes(this.arr[i])){
                        this.$message("输入不合格")
                        return
                    }
                }
                   var str = this.input4.substr(this.input4.length-2,2)
                    if(str=="公司"){
                        this.$router.push({
                            path:'/qiyecxtwo',
                            query:{
                                name:this.input4
                            }
                        })
                    }else {
                       this.$message("结尾请以公司结束")
                    }

            }
        }
    }
</script>

<style scoped lang="less">
    /deep/.el-input__icon{
        font-size: 30px;
        line-height: 70px;
    }
    /deep/.el-input__inner{
        font-size: 20px;
        padding-left: 40px;
        width: 945px;
        height: 72px;
        line-height: 20px;
        border-radius: 10px;
        border: 1px solid rgba(187, 187, 187, 100);
    }
    /deep/.el-input{
        margin: 30px 10% 0px 10%;
    }
    .container {
        background-color: #F8F9FA;
        width: 100%;
        height: auto;
        overflow: hidden;
        .search_big_box{
            width: 1200px;
            margin: 80px auto 100px auto;
        }
        .search_box{
            margin: 0 auto;
            display: flex;
            align-items: center;
            .search{
                cursor:pointer;
                z-index: 9999999;
                margin-top: 30px;
                margin-left: -30px;
                width: 106px;
                height: 43px;
                line-height: 43px;
                border-radius: 4px;
                background-color: rgba(56, 146, 235, 100);
                color: rgba(235, 245, 254, 100);
                font-size: 20px;
                text-align: center;
                font-family: Microsoft Yahei;
                border: 1px solid rgba(255, 255, 255, 100);
            }
        }
    }
	@media screen and (max-width: 1200px) {
		/deep/.el-input__inner{
			width: 40rem;
			font-size: 1.6rem;
		}
		.container .search_box .search{
			font-size: 1.6rem;
			width: 8rem;
			height: 2.8rem;
			line-height: 2.8rem;
			margin-left: -5rem;
		}
	}
</style>